.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.HeaderTab {
  /* height: 300px;
  width: 100%;
  background-image: url('./images/BED.jpg'); */
  /* position: fixed; */
  /* z-index: 200; */
  width: 100%;
  top: 0;
  left: 0;
  /* transition: all 0.4s ease; */
  background: #ffffff;
  font-family: 'Segoe UI', 'Arial', sans-serif;
}

.caps_sub {
  color: '#4b3d7a';
  font-weight: 700;
  font-family: 'Century Gothic', sans-serif;
}

ul {
  list-style-type: none;
}

ul a {
  text-decoration: none;
  /* color: 00ff91 */
}


#Home {
  background-image: url(./images/BUILDING_PHOTO.jpg);
  background-size: cover;
  background-position: center center;
  display: flex;
  min-height: 100%;
  /* position: relative; */
  width: 100%;
  height: 100vh;
  /* z-index: -5; */
}

/* 
#Home::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.65;
  z-index: -1;
} */

.links {
  font-size: 14px;
  cursor: pointer;
}

.titleHeader {
  color: #F8F8F8;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 45px;
  text-shadow: 1px 2px 10px rgba(0, 0, 0);
  line-height: 55px;
  margin: 0;
  width: 700px;
  text-align: center;
  cursor: pointer;
}

.highlightColor {
  color: #422B75;
  font-weight: 600;
}

.subTitle {
  color: #ffffff;
  font-size: 24px;
  border-left: 2px solid #00ff91;
  padding-left: 30px;
  line-height: 30px;
}

.HomeContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 100px;
  margin-top: 150px;
  width: 100%;
  align-items: center;
}

.TransperantBackground {
  /* width: 300px;
  color: #ffffff;
  background: -webkit-linear-gradient(right, rgba(255, 0, 0, 0), #00ff91);
  text-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2); */
  /* padding: 10px; */
  color: #ffffff;
  text-shadow: 1px 2px 10px rgba(0, 0, 0);
  display: flex;
  align-items: center;

}

.separator {
  rotate: 90deg;
  display: block;
  height: 4px;
  background: #fff;
  border: none;
  width: 40px;
}

.SectionHeader {
  color: #4b3d7a;
  font-size: 54px;
  line-height: 64px;
  font-weight: 700;
  text-align: center;
  font-family: 'swiss921 bt', sans-serif;
  /* display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: linear-gradient(93.62deg, #31C786 5.63%, #2bc7ed 100%) */
}

.ContactHeader {
  background: linear-gradient(93.62deg, #31C786 5.63%, #2bc7ed 100%);
  background-clip: text;
  font-size: 40px;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.ContactHeaderWrapper {
  display: flex;
  width: 100%;
  background: #F1F8FF;
  justify-content: center;
}

body {
  background-color: #FFFFFF;
}

.cardContainer {
  position: relative;
  display: flex;
  padding: 24px;
  margin: 24px;
  width: 50%;
  min-width: 600px;
  height: auto;
  box-shadow: 5px 5px 8px 2px #00000040;
  border-radius: 20px;
  background: linear-gradient(93.62deg, #31C786 5.63%, #2bc7ed 100%)
}


.AccerditationCardContainer {}

.cardTitle {
  font-size: 32px;
  color: #ffffff;
  font-weight: 900;
  margin: 0;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
}

.cardImage_2 {
  width: 100%;
  /* height: 350px; */
  object-fit: fill;
  object-position: center;
}

.cardDescription {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  line-height: 22px;
  padding: 0 16px;
}

.cardPoints,
.servicecardPoints , .servicecardPoints_2 {
  list-style-type: disc;
  font-size: 18px;
  line-height: 24px;
}

.refex {
  height: 1300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutSection,
.servicesSection,
.AccerditationSection,
.contactSection {
  padding: '36px'
    /* background: #F1F8FF; */
}

.contactSection {
  background: #e6e7e9;
  height: 120vh;
}

.servicesCard {
  box-shadow: 5px 5px 8px 2px #00000040;
  display: flex;
  flex-direction: column;
  width: 550px;
  height: auto;
  border-radius: 12px;
  background-color: #ffffff;
}

.servicesContainer {
  padding: 24px 60px;
  display: flex;
  justify-content: space-between;
}

.cardPoints {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.servicecardPoints, .servicecardPoints_2 {

  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 24px;
  line-height: 32px;
  /* font-weight: 600; */
  /* width: 50%; */
  margin: 0;
  /* color: #3e3e3e; */
}

.servicecardPoints_2 {
  width: 50%;

}

.commonText,
.commonText_2 {
  font-size: 24px;
  line-height: 32px;
  /* font-weight: 600; */

}

.commonText_2 {
  color: #ffffff;
  background-color: #4b3d7a;
}

.commonText {
  width: 50%;
  /* color: #3e3e3e; */
}

.headerText {
  /* background: linear-gradient(93.62deg, #31C786 5.63%, #2bc7ed 100%); */
  color: #4b3d7a;
  /* background-clip: text; */
  font-size: 36px;
  /* -webkit-text-fill-color: transparent; */
  line-height: 52px;
  font-weight: 900;
  text-shadow: 2px 1px 4px rgba(22, 23, 23, 0.1);
}

.services_part {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.commonText_2 {
  padding: 20px 60px 40px;
}

.AccerditationCardContainer {
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 20px;
}

.AccerditationImages {
  height: 100px;
  width: 100px;
  background: #ffffff;
  padding: 20px;

}

.imgWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

}

.AboutCard {
  display: flex;
  width: 400px;
  height: auto;
  box-shadow: 5px 5px 8px 2px #00000040;
  border-radius: 20px;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px;
  background: #ffffff;
}


.iconWrapper {
  width: 40px;
  height: 40px;
}

.AboutCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  margin: 24px 0;
  width: 45%;
}

.leftCard {
  display: flex;
  width: 400px;
  height: auto;
  box-shadow: 5px 5px 8px 2px #00000040;
  border-radius: 20px;
  background: linear-gradient(93.62deg, #31C786 5.63%, #2bc7ed 100%);
  padding: 20px;
  color: #ffffff;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.contactDetails {
  /* background: linear-gradient(93.62deg, #31C786 5.63%, #2bc7ed 100%); */
  width: 100%;
  height: auto;
  /* padding: 60px 0; */
}

.contactText {
  color: #3e3e3e;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  padding-left: 100px;
}

.contactText_2,
.contactText_3 {
  color: #3e3e3e;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  padding-left: 60px; /* Ensure consistent padding */
  width: 400px;
  margin: 0;
}


.noMargin {
  margin: 0;
}

/* .contactText_3 {
  wi
} */

.contactTitle {
  font-size: 32px;
  color: #4b3d7a;
  font-weight: 700;
  line-height: 32px;
  /* padding-left: 60px; */
}

.contactWholeWrapper {
  display: flex;
  justify-content: space-around;
}

.mapText {
  color: #3e3e3e;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  width: 460px;
  text-align: center;
}

.mapWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}


.aboutContentWrap {
  display: flex;
  gap: 10px
}

.aboutText {
  font-size: 24px;
  margin: 0;
}

.cardImage {
  background-image: url('./images/serviceCard.jpg');
}